import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"

import { SEO } from "../components/Seo"
import { Header } from "../components/Header"
import { ButtonLabel } from "../elements/ButtonLabel"
import { IconArrow } from "../icons/IconArrow"

import {
  MAPTILER_MAPYCZ_URL,
  MAPTILER_OSM_URL,
  MAPTILER_MAPTILER_URL,
} from "../providers/urls"
import {
  SITENAME,
  BUTTON__BACK,
  SITE__ABOUT_PAGE,
  MAPTILEPROVIDER__OSM,
  MAPTILEPROVIDER__MAPYCZ,
  MAPTILEPROVIDER__MAPTILER,
  MAPTILEPROVIDER__COPYRIGHT_NOTICE,
} from "../providers/texts"

import styles from "./attributions.module.scss"

const Attributions: React.FC = () => {
  const heroImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "0017/small/1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO />
      <div className={styles.container}>
        <Header resetState={() => navigate("/")} />

        <div className={styles.content}>
          <Img
            fluid={heroImage.file.childImageSharp.fluid}
            className={styles.image}
            alt={SITE__ABOUT_PAGE}
          />
          <div className={styles.text}>
            <div className={styles.description}>
              <div className={styles.description__paragraph}>
                Texty, fotografie, design, kód ©{"\xa0"}{SITENAME} (2020–2021)
              </div>
              <div className={styles.description__paragraph}>
                {MAPTILEPROVIDER__COPYRIGHT_NOTICE}{" "}
                <a
                  className={styles.description__link}
                  href={MAPTILER_MAPYCZ_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {MAPTILEPROVIDER__MAPYCZ}
                </a>
                ,{" "}
                <a
                  className={styles.description__link}
                  href={MAPTILER_MAPTILER_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {MAPTILEPROVIDER__MAPTILER}
                </a>
                ,{" "}
                <a
                  className={styles.description__link}
                  href={MAPTILER_OSM_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {MAPTILEPROVIDER__OSM}
                </a>
                .
              </div>
              <div className={styles.description__paragraph}>
                Ikona cookie ©{" "}
                <a
                  href="https://www.flaticon.com/free-icon/cookies_216341"
                  className={styles.description__link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Trinh Ho
                </a>
              </div>
            </div>

            <div className={styles.button}>
              <Link to="/">
                <ButtonLabel ariaLabel={BUTTON__BACK}>
                  <>
                    <IconArrow />
                    <div className={styles.button__label}>{BUTTON__BACK}</div>
                  </>
                </ButtonLabel>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Attributions
